import template from './template-oneclick.html';

const myController = ['templateService', 'icToastService', function (templateService, icToastService ) {
    const vm = this;
    vm.actions = [];
    vm.loading = true;
    vm.$onInit = function () {
        templateService
        .getOneClick(vm.templateId)
        .then(function(actions) {            
            vm.actions = actions;
            vm.loading = false;
        });
    }
    vm.updateMenu = (action) => {
        const { action_id: actionId } = action;
        
        templateService
        .updateOneClickMenu(vm.templateId, actionId, action)
        .then(function(resp) {

        });
    }

    vm.toggleAssignment = (action) => {
        const { action_id: actionId, enabled } = action;

        if(enabled) {
            templateService
            .enableOneClickAction(vm.templateId, actionId)
            .then(function(resp) {
                
            });
        } else {
            templateService
            .disableOneClickAction(vm.templateId, actionId)
            .then(function(resp) {
                refreshAction(actionId);
            });            
        }
    }

    const refreshAction = function(actionId) {
        templateService
        .getOneClickAction(vm.templateId, actionId)
        .then(function(resp) {
            const index = vm.actions.findIndex(x => x.action_id === actionId);
            vm.actions[index]['menu_level'] = resp[0]['menu_level'];    
        });         
    }

}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

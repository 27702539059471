const template = `


 <div class="lds-facebook"><div></div><div></div><div></div><div></div></div>

`;

const myController = [function ( ) {
    const vm = this;
    vm.loader = loaderOverride || 'lds-facebook';
}];

export default {
        bindings: { 
            loaderOverride: '<'
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

const notificationsService = ['$http', '$rootScope',
    function ($http, $rootScope) {

        var myService = this;
    
        myService.getProfiles = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/notifications/${templateId}/profiles`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };
        myService.getProfile = function (profileId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/notification/profile/${profileId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };
        myService.addProfile = function (templateId, profile) {
            return $http.post(`${$rootScope.API_URL}/api/admin/template/notification/${templateId}`, { profile })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };        
        myService.deleteProfile = function (profileId) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/notification/profile/${profileId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                       
                    throw(err.data);
                 }); 
        };
        myService.updateNotificationEvent = function (profileId, emailTemplateId, event) {
            return $http.put(`${$rootScope.API_URL}/api/admin/notification/profile/${profileId}/event/${emailTemplateId}`, { event })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };                   
        myService.addRecipient = function (profileId, recipient) {
            return $http.post(`${$rootScope.API_URL}/api/admin/notification/profile/${profileId}/recipient`, { recipient })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };
        myService.deleteRecipient = function (profileId, recipientId) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/notification/profile/${profileId}/recipient/${recipientId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err);
                 }); 
        };                             
        return myService;
    }
];

export default notificationsService;
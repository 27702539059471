import angular from 'angular';

import templateServicesService from './template-services.service';

import templateServices from './components/template-services/template-services.component';
import templateServiceItem from './components/template-service-item/template-service-item.component';

import templateServiceHeader from './components/template-service-header/template-service-header.component';
import templateServiceAddItem from './components/template-service-item/template-service-add-item.component';


export default angular.module('admin.templates.services', [])
.service('templateServicesService', templateServicesService)
.component('templateServices', templateServices)
.component('templateServiceItem', templateServiceItem)
.component('templateServiceHeader', templateServiceHeader)
.component('templateServiceAddItem', templateServiceAddItem);

import template from './template-service-header.html';

const myController = [ function ( ) {
    const vm = this;
}];

export default {
        bindings: { forms: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };


const template = require('./tier.html');

const myController = function ( ) {
    const vm = this;
    // vm.updateTier = function(tier) {
    //     alert("hello")
    // }    
};


export default {
    bindings: { 
        tier: '<',
        updateTier: '<',
        deleteTier: '<'
    },
    controller: myController,
    controllerAs: 'vm',
    template
    
};
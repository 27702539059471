
import template from './form-add-wrapper.html';
const myController = [   
    function (        
        ) {      
    const vm = this;    
  }];

  export default {
    bindings: { templateId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};




import template from './notification-add-profile.html';

const myController = [ function ( ) {
    const vm = this;

    vm.profile = {};
    vm.validateForm = function(isValid) {
        if(!isValid) {
            return false;
        }        
        vm.addProfile(vm.profile);        
    } 
}];

export default {
        bindings: { addProfile: '<', cancel: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

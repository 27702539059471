
const template = require('./tiers.html');

const myController = ['formModulesService', '$mdToast', function (formModulesService, $mdToast ) {
    const vm = this;
    vm.tiers = [];
    vm.toggleAddMode = () => {
        vm.addMode = !vm.addMode;
    }
    vm.loadTiers = function() {
        formModulesService
        .getServiceTiers(vm.templateServiceId)
        .then(function(tiers) { 
            vm.tiers = tiers;                
        });        
    }

    vm.$onInit = function () {
        vm.loadTiers();
    }

    vm.updateTier = function(tier) {
        formModulesService
        .updateTier(vm.templateServiceId, tier)
        .then(function(resp) {        
        });
    }  
    vm.addTier = function(tier) {
        formModulesService
        .addTier(vm.templateServiceId, tier)
        .then(function(resp) {
            vm.addMode = false;
            vm.loadTiers();
        });
    }        
    vm.deleteTier = (tierId) => {
        formModulesService
        .deleteTier(vm.templateServiceId, tierId)
        .then(function(resp) {            
            vm.loadTiers();            
        });
    }       

}];


export default {
    bindings: { 
        templateServiceId: '<'

    },
    controller: myController,
    controllerAs: 'vm',
    template
    
};
// import formComponent from "./admin/forms/form-wrapper.component";

export default [  '$stateProvider', '$urlRouterProvider', 
    function ( $stateProvider, $urlRouterProvider) {       
      /////////////////////////////
      // Redirects and Otherwise //
      /////////////////////////////
    //   $locationProvider.html5Mode(true);
      // Use $urlRouterProvider to configure any redirects (when) and invalid urls (otherwise).
      $urlRouterProvider.otherwise('templates');
      $stateProvider
        .state('admin', {
            abstract: true,
            component: 'nav'
        })
        .state('admin.templates', {
              abstract: true,            
              component: 'templates',
        })
        .state('admin.templates.list', {
            url: '/templates',
            component: 'templateList'        
        })   
        .state('admin.templates.add', {
            url: '/templates/add',
            component: 'templateAdd'        
        })                       
        .state('admin.templates.template', {
            url: '/template/:templateId',
            resolve: {              
                templateId: ['$stateParams',
                  function($stateParams){
                  return $stateParams.templateId;
                }]                                                          
            }, 
            templateProvider: ['templateId', (templateId) => {
                    return `<template-wrapper template-id=${templateId}></template-wrapper>`
            }]
          }) 
          .state('admin.templates.template.editor', {
            url: '/:templateEditor',
            resolve: {
                templateEditor: ['$stateParams',
                function($stateParams){
                    return $stateParams.templateEditor;
                }]                                                              
            },               
            templateProvider: ['templateEditor', 'templateId', (templateEditor, templateId) => {
                    return `<${templateEditor} template-id=${templateId}></${templateEditor}>`
              }]
          })
          .state('admin.templates.template.editor.service', {
            url: '/service/:serviceId',
            resolve: {
                serviceId: ['$stateParams',
                function($stateParams){
                    return $stateParams.serviceId;
                }]                                                              
            },
            views: {
                '@admin.templates.template': {
                    templateProvider: ['templateId', 'serviceId', (templateId, serviceId) => {
                        const templateName = serviceId === 'add' ? 'template-service-add-item' : 'template-service-item';
                        return `<${templateName} service-id=${serviceId} template-id=${templateId}></${templateName}>`
                  }]                    
              }
            }
          })
          .state('admin.templates.template.editor.notification', {
            url: '/profile/:profileId',
            resolve: {
                profileId: ['$stateParams',
                function($stateParams){
                    return $stateParams.profileId;
                }]                                                              
            },
            views: {
                '@admin.templates.template': {
                    templateProvider: ['templateId', 'profileId', (templateId, profileId) => {
                        const templateName = profileId === 'add' ? 'notification-add-profile' : 'notification-profile';
                        return `<${templateName} profile-id=${profileId} template-id=${templateId}></${templateName}>`
                  }]                    
              }
            }
          })                         
          
          // FORMS
                 
        .state('admin.templates.form', {
            url: '/template/:templateId/form/:formId',
            resolve: {              
                formId: ['$stateParams', function($stateParams){
                    return $stateParams.formId;
                }],
                templateId: ['$stateParams', function($stateParams){
                        return $stateParams.templateId;
                }]
            },
            templateProvider: ['templateId', 'formId', (templateId, formId) => {
                const templateName = formId === 'add' ? 'form-add-wrapper' : 'form-wrapper';
                return `<${templateName} form-id=${formId} template-id=${templateId}></${templateName}>`;                
                // return `<form-wrapper template-id=${templateId}></form-wrapper>`
            }]            
            // views: {
            //     '@admin.templates': {
            //         component: 'formWrapper'
            //   }              
            // }
        })          
        .state('admin.templates.form.editor', {
            url: '/:formEditor',
            resolve: {
            formEditor: ['$stateParams',
                function($stateParams){
                    return $stateParams.formEditor;
                }]                                                              
            },        
            templateProvider: ['formEditor', 'templateId', 'formId', (formEditor, templateId, formId) => {                
                    return `<${formEditor} template-id=${templateId} form-id=${formId}></${formEditor}>`
              }]
          })
          .state('admin.templates.form.editor.module', {
            url: '/module/:templateServiceId',
            resolve: {
                templateServiceId: ['$stateParams',
                function($stateParams){
                    return $stateParams.templateServiceId;
                }]                                                              
            },
            views: {
                '@admin.templates.form': {
                    templateProvider: ['templateId', 'templateServiceId', (templateId, templateServiceId) => {
                        const templateName = templateServiceId === 'add' ? 'form-module-add-item' : 'form-module';
                        return `<${templateName} template-service-id=${templateServiceId} template-id=${templateId}></${templateName}>`
                  }]                    
              }
            }
          })
              
    }
  ];

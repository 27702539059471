import template from './notification-profile-event.html';

const myController = ['notificationsService', 'icToastService', function (notificationsService, icToastService ) {
    const vm = this;
    vm.notificationEvent = {};
    vm.addRecipientMode = false;
    vm.$onInit = function () {

    }
    vm.toggleEditRecipient = function () {
        vm.addRecipientMode = !vm.addRecipientMode;
    }

    vm.addRecipient = function (recipient) {
        const { email_template_id, email_template_wrapper_id: profileId } = vm.notificationEvent;
        const newRecipient = { email_template_id, ...recipient};        
        notificationsService.addRecipient(profileId, newRecipient)
        .then(function(res) {
            vm.notificationEvent.recipients = vm.notificationEvent.recipients.concat(res);
            vm.toggleEditRecipient();
        }).catch(function(err) {
            alert(err);
        });          
    }
    vm.deleteRecipient = function (recipient) {
        const { email_template_wrapper_id: profileId } = vm.notificationEvent;
        const { recipient_id } = recipient;
        notificationsService.deleteRecipient(profileId, recipient_id)
        .then(function(res) {
            vm.notificationEvent.recipients = vm.notificationEvent.recipients.filter(x => x.recipient_id !== recipient_id);
        }).catch(function(err) {
            alert(err);
        });          
    }
    vm.updateEvent = function () {
        const { email_template_wrapper_id: profileId, email_template_id: emailTemplateId } = vm.notificationEvent;
        const { recipients, ...myEvent } = vm.notificationEvent;
        notificationsService.updateNotificationEvent(profileId, emailTemplateId, myEvent)
        .then(function(res) {
            icToastService.showSimpleToast(res);
            
        }).catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });          
    }          

}];

export default {
        bindings: { 
            notificationEvent: '<'
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

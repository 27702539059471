import template from './template-add.html';

const myController = ['templateService', 'icToastService', '$state', function (templateService, icToastService, $state ) {
    const vm = this;

    vm.loading = true;
    vm.errors = [];
    vm.template = [];
    vm.form = {};
    vm.emailProfiles = [];
    vm.logo_images = [];
    vm.header_images = [];
    vm.clients = [];
    vm.client = {};

    function getTemplate () {
        templateService
        .getNewTemplateConfig()
        .then(function(tempConfig) {                
            const { clients, account } = tempConfig;
            vm.clients = clients;
            vm.loading = false;
        });
    }


    vm.saveTemplate = () => {        
        templateService
        .saveNewTemplate(vm.client)
        .then(function(newTemplate) {                
            $state.go('admin.templates.template', {templateId: newTemplate.template_id});
        });
    }

    vm.$onInit = function () {
        getTemplate();
        
    }
 
}];

export default {
        bindings: { },
        controllerAs: 'vm',
        controller: myController,
        template
    };

import template from './login-dialog.tpl.html';

    
    const loginDirective = [
                   '$window', '$mdDialog', '$mdToast', 'AUTH_EVENTS', 'loginService',
        function (  $window, $mdDialog,    $mdToast,  AUTH_EVENTS, loginService)  {
        var myController = function () {          
            var vm = this;
            
            vm.closeModal = function (ev) {
                 $mdDialog.hide();
                 
            }
           
             vm.loadModal = function (ev) {                
                $mdDialog.show({
                    openFrom: {
                        top: -50,
                        width: 30,
                        height: 80
                    },
                    fullscreen: false,
                    parent: angular.element(document.body),
                    closeTo: {
                        left: 1500
                    },
                    template,
                    locals: {
                      timezone: vm.timezone,
                      locals:{logmein: vm.logmein},
                    },
                    controller: [
                        function ( ) {
                            vm = this;
                            vm.processing = false;                         
                            vm.login = function(isValid) {
                                if(!isValid) {
                                    return false;
                                }
                                vm.processing = true;
                                var myData = 
                                    {
                                        username: vm.username,
                                        password: vm.password                                    
                                    };
                                
                               loginService.account.login(myData)
                                .then(function(data){
                                    $mdDialog.cancel();
                                    $window.location.reload();
                                     
                                }).catch(function(err) {
                                    vm.processing = false;
                                    showSimpleToast("incorrect login");
                                });
                            }                                          
                        }
                    ],
                    controllerAs: 'vm'
                });                
            }

           // Function to make easier teh mdToast messages              
           function showSimpleToast(title) {
            $mdToast.show(
                $mdToast.simple()
                        .content(title)
                        .hideDelay(1000)                    
                        .position('bottom right')
                );
           }            
        };         
         
        return {
                restrict : "E",
                //transclude:true,
                controller:myController,
                controllerAs: 'vm',
                bindToController: true,
                scope : {
                    
                },
                link:function(scope, $rootScope, element, attr){
                    
                    scope.$on(AUTH_EVENTS.serverError, function (event, data) {
                        
                        if(!scope.vm.isModalOpen) {
                            scope.vm.isModalOpen = true;
                            scope.vm.loadModal();  
                        }
                        

                        //  if(data.status && scope.vm.isModalOpen) {
                        //     scope.vm.isModalOpen = false;
                        //     scope.vm.closeModal();
                        //  } else if(!data.status && !scope.vm.isModalOpen) {
                        //     scope.vm.isModalOpen = true;
                        //     scope.vm.loadModal();
                        // }
                    });
                }
        };
    }];    

export default loginDirective;
import template from './template-services.html';

const myController = ['templateServicesService', 'icToastService', function (templateServicesService, icToastService ) {
    const vm = this;
    vm.services = [];
    vm.loading = true;
    vm.$onInit = function () {
        templateServicesService
        .getServices(vm.templateId)
        .then(function(services) {                            
            vm.services = services;
            vm.loading = false;
        });
    }
    vm.toggleAddService = () => {
        vm.addServiceMode = !vm.addServiceMode;
    }
    vm.toggleEditService = () => {
        vm.editServiceMode = !vm.editServiceMode;
    }
    
    vm.deleteService = function (service) {
        const { service_id: serviceId } = service;
        templateServicesService
        .deleteService(serviceId)
        .then(function(resp) {
            vm.toggleEditService();
            vm.services = vm.services.filter(x => x.service_id !== serviceId);
        })
        .catch(function(err) {
            console.log(err)
            icToastService.showSimpleToast(err, true);
        });
    }
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

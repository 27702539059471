
import template from './form-wrapper.html';
const myController = [              
              '$state',
              'formService',
    function (
        $state,
        formService
        ) {
      
    const vm = this;
    vm.formEditors = [
        {
            sref: 'form-profile',
            display: 'Profile'
        },
        {
            sref: 'form-overrides',
            display: 'Overrides'
        },
        {
            sref: 'form-modules',
            display: 'Modules'
        },   
        {
            sref: 'form-payment-methods',
            display: 'Payment Methods'
        }                       
    ]
    vm.addModuleField = false;
    
    vm.$onInit =  function () {
        const myForm = formService.getConfig(vm.formId)
        .then(form => {
             vm.formName = form.template_wrapper_name
        });
        
        $state.go('.editor', { formEditor: 'form-profile'});
    }

    
  }];

  export default {
    bindings: { formId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};




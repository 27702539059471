import template from './form-overrides.html';

const myController = ['formService', '$mdToast', function (formService, $mdToast ) {
    const vm = this;    
    vm.logoOptions = [];
    vm.headerLogos = [];
    vm.$onInit = function () {

        formService
        .getConfig(vm.formId)
        .then(function(form) {                
            vm.form = form;
            
        });
        formService
        .getLogos(vm.formId)
        .then(function(logos) {
            vm.logoOptions = logos.map(x => Object.assign( {name: x.documentName, value: x.documentName, url:x.url}));
        }); 
        formService
        .getHeaderLogos(vm.formId)
        .then(function(logos) {            
            vm.headerLogos = logos.map(x => Object.assign( {name: x.documentName, value: x.documentName, url:x.url}));
        });                                    
    }

    vm.updateForm = function() {
        formService.updateOverrides(vm.formId, vm.form)
        .then(function(res) {
            showSimpleToast('Form updated successfully');
        })
    }
    vm.updateValue = function(field, value) {
        
    }
    // should turn this into a pipe
    vm.getLogoUrl = function(logoName) {
        const myLogo = vm.logos.find(x => x.documentName === logoName);
        return myLogo ? myLogo.url : "";        
    }

    function showSimpleToast(title) {
        $mdToast.show(
            $mdToast.simple()
                .content(title)
                .hideDelay(2000)
                .position('bottom right')
                .action('OK')
                .highlightAction(true)
                .highlightClass('md-hue')
            );
    }
}];

export default {
        bindings: { formId: '<', templateId: '<' },
        controllerAs: 'vm',
        controller: myController,
        template
    };

    import template from './templates.html';

    const myController = [function () {
        const vm = this;
    }];

    export default {
            bindings: { },
            controllerAs: 'vm',
            controller: myController,
            template
        };


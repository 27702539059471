import template from './template-wrapper.html';
const myController = ['$state', function ($state ) {
      
    const vm = this;
    vm.templateEditors = [
        {
            sref: 'template-profile',
            display: 'Profile'
        },
        {
            sref: 'template-profile-images',
            display: 'Logos'
        },
        {
            sref: 'template-images',
            display: 'Image Bank'
        },        
        {
            sref: 'template-services',
            display: 'Services '
        },
        {
            sref: 'notification-profiles',
            display: 'Notifications'
        },        
        {
            sref: 'template-oneclick',
            display: 'One Click'
        },
        {
            sref: 'template-permissions',
            display: 'Permissions'
        }                     
    ]
    // vm.addModuleField = false;

    vm.$onInit = function () {
        $state.go('.editor', { templateEditor: 'template-profile'});
    }
  }];

  export default {
    bindings: { templateId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};






const template = `
<div layout="row">
    <md-input-container class="md-block" flex-gt-sm>
        <label>SVG height</label>
        <input type="number" ng-model="vm.height">
    </md-input-container>   
                                
    <span flex-gt-sm="2"></span>

    <md-input-container class="md-block"  flex-gt-sm>
        <label>Y-Offset</label>
        <input type="number" ng-model="vm.yoffset">
    </md-input-container>                            

    <span flex-gt-sm="2"></span>

    <md-input-container class="md-block"  flex-gt-sm>
        <label>X-Offset</label>
        <input type="number" ng-model="vm.xoffset">
    </md-input-container>   
    </div>
`;

const myController = [function ( ) {
    const vm = this;
}];

export default {
        bindings: { 
            height: '=',
            yoffset: '=',
            xoffset: '=',
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

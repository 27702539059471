// export default ($mdThemingProvider) => {
//     var customBlueMap = $mdThemingProvider.extendPalette('grey', {
//           'contrastDefaultColor': 'light',
//           'contrastDarkColors': ['50'],
//           '50': 'ffffff'
//         });	
// $mdThemingProvider.definePalette('customBlue', customBlueMap);

// $mdThemingProvider.theme('default')
//   .primaryPalette('orange')
//   .accentPalette('indigo');
// $mdThemingProvider.theme('toolbar', 'default')
//     .primaryPalette('customBlue', {
//     'default': '500',
//     'hue-1': '50'
//   });
//   $mdThemingProvider.theme("success-toast");
//   $mdThemingProvider.theme("error-toast");    

// }

export default 
    // angular.module('themes', ['ngMaterial'])      
    ['$mdThemingProvider', function($mdThemingProvider) {
    

          $mdThemingProvider
                .theme('default')
                .primaryPalette('grey', {
                'default': '600'
                })
                .accentPalette('blue-grey', {
                'default': '500'
                })
                .warnPalette('defaultPrimary');
          
          $mdThemingProvider.theme('dark', 'default')
                .primaryPalette('defaultPrimary')
                .dark();
          
          $mdThemingProvider.theme('grey', 'default')
                .primaryPalette('grey');
          
          $mdThemingProvider.theme('custom', 'default')
                .primaryPalette('defaultPrimary', {
                'hue-1': '50'
            });

// $mdThemingProvider.theme('default')
//   .primaryPalette('orange')
//   .accentPalette('indigo');
// $mdThemingProvider.theme('toolbar', 'default')
//     .primaryPalette('customBlue', {
//     'default': '500',
//     'hue-1': '50'
//   });

          
          $mdThemingProvider.definePalette('defaultPrimary', {
                '50':  '#FFFFFF',
                '100': 'rgb(255, 198, 197)',
                '200': '#E75753',
                '300': '#E75753',
                '400': '#E75753',
                '500': '#E75753',
                '600': '#E75753',
                '700': '#E75753',
                '800': '#E75753',
                '900': '#E75753',
                'A100': '#E75753',
                'A200': '#E75753',
                'A400': '#E75753',
                'A700': '#E75753'
          });
       
      
       
       
        $mdThemingProvider.theme('docs-dark', 'default')
          .primaryPalette('yellow')
          .dark();
          
        $mdThemingProvider.theme('alt-theme')
          .primaryPalette('light-blue', {
          'default': '400', // by default use shade 400 from the pink palette for primary intentions
          'hue-1': '100', // use shade 100 for the <code>md-hue-1</code> class
          'hue-2': '600', // use shade 600 for the <code>md-hue-2</code> class
          'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
          });
          
                
    }];

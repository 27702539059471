
const template = require('./form-module-add-service.html');

const myController = ['formModulesService', function ( formModulesService ) {
    const vm = this;
    vm.loading = false;
    vm.services = [];

    vm.$onInit = function () {
        vm.loading = true;
        formModulesService
        .getServices(vm.templateId)
        .then(function(services) {
            vm.services = services;
            vm.loading = false;
        });
    }    

}];


export default {
    bindings: {         
        templateId: '<',
        addService: '<'
    },
    controller: myController,
    controllerAs: 'vm',
    transclude: true,
    template    
};
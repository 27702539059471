
const template = require('./form-module.html');

const myController = ['formModulesService', 'icToastService', function (formModulesService, icToastService ) {
    const vm = this;
    vm.loading = true;
    vm.services = [];
    vm.module;
    vm.images = [];
    vm.editItemId = {};
    vm.addMode = false;
    vm.loadModule = function() {
        formModulesService
        .getServiceView(vm.templateServiceId)
        .then(function(services) { 
            vm.services = services;
            vm.module = vm.services.find(x => !x.parent_service_id);
            vm.setEditItem();
            vm.loading = false;
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
            vm.loading = false;
        });
    }
    vm.addService = function(serviceId) {
        formModulesService
        .addService(vm.templateServiceId, serviceId)
        .then(function(resp) { 
            vm.loadModule();
            vm.addMode = false;
            icToastService.showSimpleToast(resp);
            
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        });
    }

    vm.updateImage = function () {
        const { service_logo: override_service_logo } = vm.module;
        formModulesService
        .patchServiceConfig(vm.templateServiceId, { override_service_logo })
        .then(function() { 
            vm.loadModule();
            vm.editImageMode = !vm.editImageMode;
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        });                
    }

    vm.setEditItem = function (item) {
        if(!item) {
            vm.editItemId = null;
            return false;    
        }
        vm.editItemId = (item.template_service_id === vm.editItemId) ? null : item.template_service_id;        
    }
    vm.toggleAddMode = function () {
        vm.addMode = !vm.addMode;
    }

    vm.$onInit = function () {
        vm.loadModule();

        formModulesService
        .getServiceImages(vm.templateId)
        .then(function(images) {
            vm.images = images;            
        });  
    }


}];


export default {
    bindings: {
        templateId: '<',
        templateServiceId: '<'

    },
    controller: myController,
    controllerAs: 'vm',
    template    
};
const formService = ('formService', ['$http', '$rootScope', 'AUTH_EVENTS',
    function ($http, $rootScope, AUTH_EVENTS) {

        var myService = this;

        myService.getForm = function (formId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/profile/${formId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };
        myService.addForm = function (templateId, form) {            
            return $http.post(`${$rootScope.API_URL}/api/admin/template/form/${templateId}/add`, form)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };        
        myService.getConfig = function (formId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/config/${formId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };
        myService.updateOverrides = function (formId, form) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/form/overrides/${formId}`, form)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };         
        myService.updateForm = function (formId, form) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/form/config/${formId}`, form)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };        
        myService.getEmailProfiles = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/email-profiles/${templateId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        }; 
        myService.getLogos = function (formId) {            
            return $http.get(`${$rootScope.API_URL}/api/admin/form/images/${formId}/logo`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };
        myService.getHeaderLogos = function (formId) {            
            return $http.get(`${$rootScope.API_URL}/api/admin/form/images/${formId}/header`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };        
     
        myService.getPaymentMethods = function (formId) {            
            return $http.get(`${$rootScope.API_URL}/api/admin/form/payment-methods/${formId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };
        
        myService.enablePaymentMethod = function (formId, paymentMethodId) {            
            return $http.patch(`${$rootScope.API_URL}/api/admin/form/payment-methods/${formId}/enable/${paymentMethodId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };
        myService.disablePaymentMethod = function (formId, paymentMethodId) {            
            return $http.patch(`${$rootScope.API_URL}/api/admin/form/payment-methods/${formId}/disable/${paymentMethodId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                });
        };                  


                   


        return myService;
    }])
export default formService;

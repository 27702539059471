const template = `

 
<div layout-gt-sm="row" layout-align="space-between stretch">
    <div layout-gt-sm="row" layout-align="space-between stretch">

        <md-input-container class="md-block">
            <label>{{vm.label}}</label>
            <ic-dropdown
                items="vm.imageOptions"
                value="vm.imageValue"                   
            ></ic-dropdown>
            
            <ic-image-offsets
                ng-if="vm.showOverrides"
                force-value="vm.forceValue"
                height="vm.height"
                yoffset="vm.yoffset"
                xoffset="vm.xoffset">                                
            </ic-image-offsets>

        </md-input-container>
        
        <span flex-gt-sm="2"></span>
        
    </div>

    <span flex></span>

    <div layout-gt-sm="column">
        <div ng-if="vm.imageValue" layout-gt-sm="column">
            <md-content md-theme="default">
                <img class="service-image-height" ng-src="{{vm.getImageUrl()}}" />
            </md-content>
        </div>
    </div>
</div>                        

`;

const myController = [function ( ) {
    const vm = this;


    vm.getImageUrl = function() {
        if(!Array.isArray(vm.imageOptions)) {
            return "";
        }
        const myLogo = vm.imageOptions.find(x => x.value === vm.imageValue);

        return myLogo ? myLogo.url : "";        
    }
}];

export default {
        bindings: { 
            imageOptions: '<',            
            imageValue: '=',
            label: '<',
            forceValue: '<',
            showOverrides: '<',
            height: '=',
            yoffset: '=',
            xoffset: '=',
            
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

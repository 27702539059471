import template from './template-images.html';

const myController = ['templateService', 'icToastService', function (templateService, icToastService ) {
    const vm = this;
    vm.loading = true;
    vm.images = [];
    vm.imageType = 'logo';
    vm.loading = false;
    vm.$onInit = function () {
        vm.loadImages();
    }
    vm.loadImages = function() {
        vm.images = [];
        vm.loading = true;
        templateService
        .getImages(vm.templateId, vm.imageType)
        .then(function(images) {                
            vm.images = images;
            vm.loading = false;
        }).catch(function(err) {
            icToastService.showSimpleToast(err, true);
            vm.loading = false;
        });
    }
    
    vm.upload = function(  ) {
        vm.loading = true;
        templateService.uploadImage(vm.templateId, vm.imageType, vm.fileName)
            .then(function (data) {
                icToastService.showSimpleToast('Document sucessfully added');                
                vm.loadImages();
                vm.loading = false;
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
            vm.loading = false;
        });
    }

    vm.deleteFile = function (document) {
        vm.loading = true;
        vm.images = vm.images.filter(x => x.documentName !== document.documentName);
        templateService.deleteImage(vm.templateId, vm.imageType, document.documentName)
            .then(function (data) {
                icToastService.showSimpleToast('Document sucessfully deleted');
                vm.loadImages();
                vm.loading = false;
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
            vm.loading = false;
        });
    }
 
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

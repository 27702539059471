import template from './nav.html';

const myController = [
    '$mdSidenav',
    '$mdBottomSheet',
    '$log',
    '$q',
    '$mdToast',
    function (
        $mdSidenav,
        $mdBottomSheet,
        $log,
        $q,
        $mdToast
    ) {
        const vm = this;

        vm.menuItems =

            [
                { 
                    "name": "Templates", "icon": "fa-cube", "sref": ".templates.list" 
                }, 
                { 
                    "name": "Settings", "icon": "fa-envelope-o", "sref": ".settings" 
                }
            ]


        // vm.menuItems = [ ];
        // vm.selectItem = selectItem;
        // vm.toggleItemsList = toggleItemsList;
        // vm.showActions = showActions;

        // vm.showSimpleToast = showSimpleToast;    



        function toggleItemsList() {
          var pending = $mdBottomSheet.hide() || $q.when(true);

          pending.then(function(){
            $mdSidenav('left').toggle();
          });
        }

        function selectItem (item) {
          vm.title = item.name;
          vm.toggleItemsList();
          //vm.showSimpleToast(vm.title);
        }

        function showActions($event) {
            $mdBottomSheet.show({
              parent: angular.element(document.getElementById('content')),
              templateUrl: 'app/views/partials/bottomSheet.html',
              controller: [ '$mdBottomSheet', SheetController],
              controllerAs: "vm",
              bindToController : true,
              targetEvent: $event
            }).then(function(clickedItem) {
              clickedItem && $log.debug( clickedItem.name + ' clicked!');
            });

            function SheetController( $mdBottomSheet ) {
              var vm = this;

              vm.performAction = function(action) {
                $mdBottomSheet.hide(action);
              };
            }
        }

        function showSimpleToast(title) {
          $mdToast.show(
            $mdToast.simple()
              .content(title)
              .hideDelay(2000)
              .position('top right')
          );
        }        
    }];


export default {
    bindings: {},
    controllerAs: 'vm',
    controller: myController,
    template
};
const loginService = ('loginService', ['$http', '$rootScope', '$q',  'AUTH_EVENTS',
        function ($http, $rootScope, $q, AUTH_EVENTS) {
            let profile = {};
            var myService = this;
            const setProfile = () => {
                
                return $http({
                    method: "get",
                    url: `${$rootScope.AUTH_URL}/auth/account`,                            
                }).then(function (resp) { 
                    this.profile = resp.data;                           
                    return resp.data;
                })
            }   
            myService.account = {
                profile: {},
                login: function login (credentials) {
                    return $http({
                        method: "post",
                        url: `${$rootScope.API_URL}/auth/token/create`,
                        headers: { 'Content-Type': 'application/json' },
                        data: credentials
                    }).then(function (resp) {                        
                        if (resp.status === 200) {  
                            // $localStorage.token = resp.data.token;                            
                            localStorage.setItem("tokenKey", resp.data.token);
                            
                            // Authorization.authorized = true;

                            // $rootScope.$broadcast('AUTH', {
                            //     status: true
                            // });
                            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);                           
                        }
                        // return setProfile().then(function (profile)  {
                        //     return profile;
                        // });
                        
                    })
                },
                logout: function logout() {                    
                    localStorage.removeItem("tokenKey");
                    $rootScope.$broadcast('AUTH', {
                        status: false
                    });                    
                },
                // getProfile: function get() {
                //     return setProfile();
                // }

            };
            return myService;
        }])
export default loginService;

const template = `
   <md-select        
        placeholder="{{vm.label}}"
        ng-model="vm.value">
           <md-option ng-if="vm.forceValue" ng-value="">...Use default</md-option>                                                                     
           <md-option
               ng-repeat="item in vm.items"
               ng-value="item.value">
               {{item.name}}
           </md-option>
       </md-select>   
  
`;

const myController = [function ( ) {
    const vm = this;
}];

export default {
        bindings: { 
            items: '<',
            label: '<',
            value: '=',
            forceValue: '<'
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

import angular from 'angular';

import notificationsService from './notifications.service';

import notificationProfiles from './components/notification-profiles/notification-profiles.component';
import notificationProfile from './components/notification-profile/notification-profile.component';
import notificationAddProfile from './components/notification-add-profile/notification-add-profile.component';
import notificationProfileEvent from './components/notification-profile-event/notification-profile-event.component';
import notificationAddRecipient from './components/notification-add-recipient/notification-add-recipient.component';


export default angular.module('admin.templates.notifications', [])

.service('notificationsService', notificationsService)
.component('notificationProfiles', notificationProfiles)
.component('notificationProfile', notificationProfile)
.component('notificationAddProfile', notificationAddProfile)
.component('notificationProfileEvent', notificationProfileEvent)
.component('notificationAddRecipient', notificationAddRecipient)


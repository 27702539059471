  /**
   * Allows variables to be dynamically set via ParcelJS
   */
  
  module.exports = (function () {
        window.__env = window.__env || {};
        if(!process.env.API_URL || !process.env.AUTH_URL) {
            alert('API_URL and AUTH_URL must be set in the .env');
        } else {
            window.__env.API_URL = process.env.API_URL;
            window.__env.AUTH_URL = process.env.AUTH_URL;
        }
        // Base url
        // window.__env.baseUrl = '/';
      
        // Whether or not to enable debug mode
        // Setting this to false will disable console output
        // window.__env.enableDebug = true;    
    
  }(window));

const template = require('./form-service-edit-item.html');

const emptyToNull = (value) => {
    return value === '' ? null : value;
}


const myController = ['$state', 'formModulesService', 'icToastService', function ($state, formModulesService, icToastService ) {
    const vm = this;
    vm.loading = false;
    vm.$onInit = function() {        
        vm.loading = true;
        formModulesService
        .getServiceConfig(vm.serviceView.template_service_id)
        .then(function(service) { 
            vm.loading = false;
            vm.service = service;            
        });        
    }

    
    vm.updateService = function(isValid) {

        if(!isValid) {
            return false;
        }
        // make sure there are no empty strings
        Object.keys(vm.service)
        .map(value => {            
            vm.service[value] = emptyToNull(vm.service[value]);
        })
   
        formModulesService
        .updateServiceConfig(vm.serviceView.template_service_id, vm.service)
        .then(function(resp) { 
            vm.dirtyStatus = true;
            if(resp){
             icToastService.showSimpleToast(resp);
            }
            else{
                icToastService.showSimpleToast('An error occurred, please try later.');
            }
            vm.reportBack();
        });        
    }


    vm.orderUp = function() {
        vm.service = {...vm.service, display_order: vm.service.display_order -1 }
    }

    vm.deleteService = function() {
        formModulesService
        .deleteService(vm.serviceView.template_service_id)
        .then(function(resp) {
            if (!vm.serviceView.parent_template_service_id) {
                $state.go('.^');
            } else {
                vm.reportBack();            
            }
            
            
        }).catch(function (err) {
            console.log('err:', err)
            icToastService.showSimpleToast(err, true);
        });
    }

}];


export default {
    bindings: {         
        serviceView: '<',        
        cancel: '<',
        reportBack: '<',
        allowReorder: '<'
    },
    controller: myController,
    controllerAs: 'vm',
    template
    
};
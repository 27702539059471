import template from './template-support.html';

const myController = ['templateService', 'icToastService', function (templateService, icToastService ) {
    const vm = this;
    vm.template = {};
    vm.loading = true;
    vm.$onInit = function () {
        templateService
        .getTemplate(vm.templateId)
        .then(function(template) {                
            vm.template = template;
            vm.loading = false;
        });
    }
    
    vm.updateTemplate = function() {
        templateService.updateTemplate(vm.templateId, vm.template)
        .then(function(res) {
            icToastService.showSimpleToast('Support updated successfully');
        })
    }
    
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

export const serviceModule = () => {
    // Filters down to the service items assigned to the parent service (module)
    return function(input) {
        if(!Array.isArray(input)) {
            return {};
        };
        return input.find(function (el) { return el.parent_template_service_id === null});
    }
};

export const serviceModules = () => {
    // Filters down to the service items assigned to the parent service (module)
    return function(input) {
        if(!Array.isArray(input)) {
            return [];
        };
        return input.filter(function (el) { return el.parent_template_service_id === null});
    }
};
export const serviceExtensions = () => {
    // Filters down to the service items assigned to the parent service (module)
    return function(input, selected_service_id) {
        if(!Array.isArray(input)) {
            return [];
        };
        return input.filter(function (el) { return el.parent_template_service_id === selected_service_id});
    }
};

export const arrayLength = () => {
    // Filters down to the service items assigned to the parent service (module)
    return function(input) {                
        if(!Array.isArray(input)) {
            0
        };
        return input.length;
    }
};

export const percentage =  ['$filter', function ($filter) {
    return function (input, decimals) {
      return $filter('number')(input * 100, decimals) + '%';
    };
  }];
import template from './notification-add-recipient.html';

const myController = ['notificationsService', function (notificationsService ) {
    const vm = this;
    vm.recipient = {recipient_type: 'to'};
    vm.recipientTypes = ['to', 'cc', 'bcc']
    vm.$onInit = function () {
       
    }

    vm.validateForm = function(isValid) {
        if(!isValid) {
            return false;
        }        
        vm.addRecipient(vm.recipient);        
    }        
}];

export default {
        bindings: { 
            cancelEdit: '<', 
            addRecipient: '<'
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

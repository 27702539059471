import angular from 'angular';

import formModulesService from './form-modules.service';

import { 
    serviceModule,
    serviceModules,
    serviceExtensions,
} from './form-modules.pipe.js';

import formModules from './components/form-modules/form-modules.component';
import formServiceItem from './components/form-service-item/form-service-item.component';
import formServiceEditItem from './components/form-service-edit-item/form-service-edit-item.component';
import formModule from './components/form-module/form-module.component';
import formModuleAddService from './components/form-module-add-service/form-module-add-service.component';
import formModuleHeader from './components/form-module-header/form-module-header.component';
import tier from './components/tiers/tier.component';
import tiers from './components/tiers/tiers.component';


export default angular.module('admin.forms.modules', [])
.service('formModulesService', formModulesService)
.filter('serviceModule', serviceModule)
.filter('serviceModules', serviceModules)
.filter('serviceExtensions', serviceExtensions)
.component('formModules', formModules)
.component('formModuleHeader', formModuleHeader)
.component('formServiceItem', formServiceItem)
.component('formServiceEditItem', formServiceEditItem)
.component('formModule', formModule)
.component('formModuleAddService', formModuleAddService)

.component('tiers', tiers)
.component('tier', tier);

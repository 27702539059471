import angular from 'angular';

// import const ngJwt = require('angular-jwt');
export default angular.module('interceptors', [])
.factory('jwtInterceptor', [  '$rootScope', '$q', 'AUTH_EVENTS', function( $rootScope, $q, AUTH_EVENTS) {  
  return {
    request: function(config) {
      config.headers = config.headers || {};
      if (localStorage.getItem("tokenKey")) {
          config.headers.Authorization = 'Bearer ' + localStorage.getItem("tokenKey");
      }
      return config;
    },

    requestError: function(config) {
      return config;
    },

    response: function(res) {
      
      return res;
    },

    responseError: function(res) {
        
      if (res.status === 401) {
        $rootScope.$broadcast(AUTH_EVENTS.serverError);
        // localStorage.removeItem("tokenKey");
        $rootScope.$broadcast('AUTH', {      
          status: false
        });       
        
        // localStorageService.set('auth', false);
      }
      return $q.reject(res);          
      
    }
  }
}])
.config(['$httpProvider', function($httpProvider) {      
    $httpProvider.interceptors.push('jwtInterceptor');
}]);


import * as myEnv from "./env.js";
import './sass/app.scss'
import app from './app.js';

import nav from './nav/nav.component.js';
import routes from './routes';
import theming from './app.theming';
app.config(theming)

// .component('form', form)
.component('nav', nav)
.config(routes);
    
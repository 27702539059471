
const template = require('./form-service-item.html');

const myController = [function ( ) {
    const vm = this;    

}];


export default {
    bindings: {         
        formId: '<',
        templateServiceId: '<',
        displayOrder: '<',
        item: '<',
        edit: '<',
        toggleEdit: '<',
        
    },
    controller: myController,
    controllerAs: 'vm',
    transclude: true,
    template    
};
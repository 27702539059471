    import template from './template-list.html';
    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };


    
    const uniqueObjectArray = (a) => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

    const myController = ['templateService', function (templateService) {
        const vm = this;
        vm.showActive = 1;

        vm.toggleActiveState = () => {
            vm.showActive = Number(Boolean(!vm.showActive));

        }

        vm.loading = true;
        vm.errors = [];
        vm.templates = [];
        vm.forms = [];
        vm.$onInit = function () {
            templateService
            .loadAllItems()
            .then(function(forms) {                
                vm.forms = forms;
                vm.templates =  uniqueObjectArray(forms.map(form => ({ template_id: form.template_id, template: form.template })));
                vm.loading = false;
            });
        }

    }];

    export default {
            bindings: { },
            controllerAs: 'vm',
            controller: myController,
            template
        };


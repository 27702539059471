import template from './template-service-item.html';

const myController = ['templateServicesService', '$mdToast', '$state', function (templateServicesService, $mdToast, $state ) {
    const vm = this;
    vm.service = {
        // service: '',
        // list_price: 0,
        // description: '',
        passthrough_revenue: 0,
        licensing_indirect: 1
    };
    vm.images = [];
    vm.$onInit = function () {
        
        templateServicesService
        .getServiceImages(vm.templateId)
        .then(function(images) {                
            vm.images = images;
        });        
    }


    vm.updateService = function(isValid) {
        if(!isValid) {
            return false;
        }        
        templateServicesService.addService(vm.templateId, vm.service)
        .then(function(newService) {            
            $state.go('^.service', { serviceId: newService.service_id})
            
            
        })
    }    
    
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

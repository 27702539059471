const formModulesService = ['$http', '$rootScope',
    function ($http, $rootScope) {

        var myService = this;
    
        myService.getServices = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/services/${templateId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };

        myService.getServicesView = function (formId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/services/view/${formId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.getServiceView = function (serviceId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/service/view/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };         
        
        myService.getServiceImages = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/assets/${templateId}/images/service`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };      
        
        myService.getServicesConfig = function (formId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/services/config/${formId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };          
        
        myService.getServiceConfig = function (templateServiceId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/service/config/${templateServiceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.updateServiceConfig = function (templateServiceId, serviceItem) {
            return $http.put(`${$rootScope.API_URL}/api/admin/form/service/config/${templateServiceId}`, { service: serviceItem })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.patchServiceConfig = function (templateServiceId, serviceItem) {
            return $http.put(`${$rootScope.API_URL}/api/admin/form/service/config/${templateServiceId}`, { service: serviceItem })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };


        myService.getServiceTiers = function (serviceId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/form/service/tiers/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };                   

        myService.updateTier = function (serviceId, tier) {
            return $http.put(`${$rootScope.API_URL}/api/admin/form/service/tier/${serviceId}`, { tier })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.addTier = function (serviceId, tier) {
            return $http.post(`${$rootScope.API_URL}/api/admin/form/service/tier/${serviceId}`, { tier })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };         
        myService.deleteTier = function (serviceId, tierId) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/form/service/tier/${serviceId}/${tierId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };

        myService.addModule = function (formId, serviceId) {            
            return $http.post(`${$rootScope.API_URL}/api/admin/form/services/add/${formId}/service/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        
        myService.addService = function (moduleId, serviceId) {
            return $http.post(`${$rootScope.API_URL}/api/admin/form/service/config/${moduleId}/add/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };


        myService.deleteService = function (templateServiceId) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/form/service/config/${templateServiceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };

        return myService;
    }
];

export default formModulesService;
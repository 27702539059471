const notificationsService = ['$http', '$rootScope',
    function ($http, $rootScope) {

        var myService = this;
    
        myService.getServices = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/services/${templateId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.getService = function (serviceId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/service/view/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.getForms = function (serviceId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/service/forms/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };             
        myService.addService = function (templateId, service) {
            return $http.post(`${$rootScope.API_URL}/api/admin/template/service/${templateId}`, { service })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.deleteService = function (serviceId, service) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/service/${serviceId}`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };        
        myService.updateService = function (serviceId, service) {
            return $http.put(`${$rootScope.API_URL}/api/admin/service/${serviceId}`, { service })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };
        myService.getServiceImages = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/assets/${templateId}/images/service`)
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };        
        myService.updateServiceImage = function (serviceId, service_logo) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/service/${serviceId}`, { service: { service_logo } })
                .then(function (res) {
                    return res.data
                }).catch(function(err) {                     
                    throw(err.data);
                }); 
        };   
        return myService;
    }
];

export default notificationsService;
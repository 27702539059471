import template from './notification-profiles.html';

const myController = ['notificationsService', '$state', 'icToastService', '$mdToast', function (notificationsService, $state, icToastService, $mdToast ) {
    const vm = this;

    vm.loading = true;

    vm.profiles = [];
    vm.$onInit = function () {

        notificationsService
        .getProfiles(vm.templateId)
        .then(function(profiles) {         
            vm.profiles = profiles;
            vm.loading = false;
        });
    }
    vm.toggleAddProfile = () => {
        vm.addProfileMode = !vm.addProfileMode;
    }
    vm.toggleEditProfile = () => {
        vm.editProfileMode = !vm.editProfileMode;
    }    
    vm.addProfile = function (profile) {
        notificationsService
        .addProfile(vm.templateId, profile)
        .then(function(newProfile) {
            vm.toggleAddProfile();
            vm.profiles = [...vm.profiles, newProfile];
            $state.go('.notification', { profileId: newProfile.email_template_wrapper_id });
        });
    }
    vm.deleteProfile = function (profile) {        
        notificationsService
        .deleteProfile(profile.email_template_wrapper_id)
        .then(function(resp) {
            vm.toggleEditProfile();
            vm.profiles = vm.profiles.filter(x => x.email_template_wrapper_id !== profile.email_template_wrapper_id);            
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });
    }

}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

import template from './form-module-header.html';

const myController = [ function ( ) {
    const vm = this;
}];

export default {
        bindings: {
            toggleAddMode: '<'
        },
        controllerAs: 'vm',
        controller: myController,
        template
    };

import angular from 'angular';
// import formsService from './forms.service';
import formService from './form.service';
import formWrapper from './components/form-wrapper/form-wrapper.component';
import formProfile from './components/form-profile/form-profile.component';
import formOverrides from './components/form-overrides/form-overrides.component';
import formPaymentMethods from './components/form-payment-methods/form-payment-methods.component';

import formAddWrapper from './components/form-add/form-add-wrapper.component';
import formAdd from './components/form-add/form-add.component';

import icDropdown from '../common/ic-dropdown.component';
import icImageOffsets from '../common/ic-image-offsets.component';
import icImageWrapper from '../common/ic-image-wrapper.component';
import { 
    arrayLength,
    percentage

} from '../common/services.pipe.js';

// import './modules/form-modules-old/form-modules.module';
import './modules/form-modules/form-modules.module';
//'admin.forms.services'
export default angular.module('admin.forms', ['admin.forms.modules'])
// .service('formsService', formsService)
.service('formService', formService)
.component('formWrapper', formWrapper)
.component('formProfile', formProfile)
.component('formAdd', formAdd)
.component('formAddWrapper', formAddWrapper)
.component('formOverrides', formOverrides)
.component('icDropdown', icDropdown)
.component('icImageOffsets', icImageOffsets)
.component('icImageWrapper', icImageWrapper)

.filter('percentage', percentage)
.filter('arrayLength', arrayLength)
// .controller('addFormController', addFormController)

.component('formPaymentMethods', formPaymentMethods);

/**
 * Dynamically load environment variables
 * Prior to loading angular js set the variables in the browsers window.__env.x 
 * Currently using ParcelJs to load from the .env file at build time
**/ 
window.__env.apiUrl
var env = {};

if(window){  
    Object.assign(env, window.__env);
}

import angular from 'angular';
import interceptors from './interceptors.module';
import appFilters from './filters.module';

import './admin/forms/forms.module';
import './admin/templates/template.module';
import './admin/common/ic-common.module';

import 'angular-animate';
import 'angular-aria';
import 'angular-material';
import '@uirouter/angularjs';
import 'angular-ui-bootstrap';
import './login/login.module';

// require('angular-material-source');
import 'ngstorage';
import 'angular-sanitize';
import 'angular-bootstrap';
import 'angular-resource';

export default angular.module('invoice-admin', [
  'ngAnimate',
  'ui.router',
  'ui.bootstrap',
  'ngMaterial',
  'ngAria',  
  'ngStorage',
  'interceptors',
  'appFilters',
  'admin.templates.common',
  'admin.templates',
  'admin.forms',
  'login'
 
])
.constant('AUTH_EVENTS', {
  loginSuccess: 'auth-login-success',
  loginFailed: 'auth-login-failed',
  logoutSuccess: 'auth-logout-success',
  sessionTimeout: 'auth-session-timeout',
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized',
  serverError: 'auth-server-error',
})
.run(
  [           '$rootScope', '$state', '$stateParams', 'AUTH_EVENTS',
    function ($rootScope,   $state,   $stateParams, AUTH_EVENTS) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.API_URL = env.API_URL;
    $rootScope.AUTH_URL = env.AUTH_URL;


    $rootScope.$on('$locationChangeSuccess', function(e) {
    });

     $rootScope.$on(AUTH_EVENTS.serverError, (event, data) => {
    });


    $rootScope.$on(AUTH_EVENTS.loginSuccess, (event, data) => {
        $state.go($state.current, {}, {reload: true});
      // reload state
      // Throws an error if there is no state, such as when it is an initial login      
    //   if(!$state.current.name) {
    //     $state.go('invoices.list', {status: 'open', stage:1}, {reload: true})
    //   } else {
    //     $state.go($state.current, {}, {reload: true});
    //   }
    });
    }
  ]
)


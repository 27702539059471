import angular from 'angular';
import 'ng-file-upload';
import './modules/template-notifications/notifications.module';
import './modules/template-services/template-services.module';

import icLoader from '../common/ic-loader.component';
import templateService from './template.service';
import templateList from './components/template-list/template-list.component';
import templates from './components/templates/templates.component';
import templateAdd from './components/template-add/template-add.component';
import templateWrapper from './components/template-wrapper/template-wrapper.component';
import templateProfile from './components/template-profile/template-profile.component';
import templateSupport from './components/template-support/template-support.component';
import templateProfileImages from './components/template-profile-images/template-profile-images.component';
import templateImages from './components/template-images/template-images.component';
import templateOneclick from './components/template-oneclick/template-oneclick.component';
import templatePermissions from './components/template-permissions/template-permissions.component';

import { 
    templateForms,
} from './templates.pipe.js';




export default angular.module('admin.templates', ['ngFileUpload', 'admin.templates.notifications', 'admin.templates.services' ])
.service('templateService', templateService)
.filter('templateForms', templateForms)

.component('icLoader', icLoader)
.component('templates', templates)
.component('templateAdd', templateAdd)

.component('templateList', templateList)
.component('templateWrapper', templateWrapper)
.component('templateProfile', templateProfile)
.component('templateProfileImages', templateProfileImages)

.component('templateSupport', templateSupport)
.component('templateImages', templateImages)
.component('templateOneclick', templateOneclick)
.component('templatePermissions', templatePermissions);

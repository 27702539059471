const templateService = ['$http', '$rootScope', 'Upload',
    function ($http, $rootScope, Upload) {

        var myService = this;
        myService.services = {};
        var templates = $http({
            method: "get",
            url: `${$rootScope.API_URL}/api/admin/forms`,
        }).then(function (resp) {
            return resp.data;
        }).catch(function (err) {
            throw ("Error uploading image");
        });

        myService.loadAllItems = function ($q) {
            return templates.then(function (data) {
                return data;
            }).catch(function (err) {
                throw ("Error getting templates");
            });
        };

        myService.getTemplate = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/config/${templateId}`)
                .then(function (res) {
                    return res.data
                }).catch(function (err) {
                    throw ("Error getting template");
                });
        };

        myService.updateTemplate = function (templateId, template) {
            return $http.put(`${$rootScope.API_URL}/api/admin/template/config/${templateId}`, { template })
                .then(function (res) {
                    return res.data               
                }).catch(function (err) {
                    throw ("Error uploading image");
                });                
        };

              
        myService.getImages = function (templateId, imageType) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/assets/${templateId}/images/${imageType}`)
            .then(function (res) {
                return res.data;
            });
        };

        myService.uploadImage = function (templateId, imageType, file) {
            return Upload.upload({
                method: "post",
                url: `${$rootScope.API_URL}/api/admin/template/assets/${templateId}/image/${imageType}`,
                data: { fileName: file }
            }).then(function (resp) {
                return resp.data;
            }).catch(function (err) {
                throw ("Error uploading image");
            });
        };

         myService.deleteImage = function (templateId, imageType, documentName) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/template/assets/${templateId}/image/${imageType}/${documentName}`)
                .then(function (res) {
                    return res.data;
                });
        };
    
        myService.getOneClick = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/oneclick/${templateId}`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };
        myService.getOneClickAction = function (templateId, actionId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/oneclick/${templateId}/action/${actionId}`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };        

        myService.getNewTemplateConfig = function () {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/add`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };
        myService.saveNewTemplate = function (template) {
            return $http.post(`${$rootScope.API_URL}/api/admin/template/add`, { ...template })
            .then(function (res) {
                return res.data;
            }).catch(function(err) {
                throw(err.data);
            });
        };        
        
        myService.updateOneClickMenu = function (templateId, actionId, action) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/template/oneclick/${templateId}/action/${actionId}/menu`, { action })
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };


        myService.enableOneClickAction = function (templateId, actionId) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/template/oneclick/${templateId}/action/${actionId}/enable`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };
        myService.disableOneClickAction = function (templateId, actionId) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/template/oneclick/${templateId}/action/${actionId}/disable`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };                              
    

        myService.getUsers = function (templateId) {
            return $http.get(`${$rootScope.API_URL}/api/admin/template/users/${templateId}`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };

        myService.updatePermision = function (templateId, userId, permissionLevel) {
            return $http.patch(`${$rootScope.API_URL}/api/admin/template/permissions/${templateId}/user/${userId}/level/${permissionLevel}`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };
        myService.deletePermision = function (templateId, userId) {
            return $http.delete(`${$rootScope.API_URL}/api/admin/template/permissions/${templateId}/user/${userId}`)
            .then(function (res) {
                return res.data;
            }).catch(function(err) {                     
                throw(err.data);
            });
        };        

        return myService;
    }
];

export default templateService;
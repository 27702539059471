
const template = require('./form-payment-methods.html');

const myController = ['formService', 'icToastService', function ( formService, icToastService ) {
    const vm = this;
    vm.paymentMethods = [];
    vm.toggleStatus = function(item) {
        const activeItems = vm.paymentMethods.find(x => x.active);
        if(!activeItems) {
            icToastService.showSimpleToast('At least one payment method must be active', true);
            vm.paymentMethods = vm.paymentMethods.map(x => {                
                const activeStatus = x.payment_method_id === item.payment_method_id ? + !item.active : x.status;
                return Object.assign(x, {active: activeStatus});
                
            });
            return false;
        }

        const { active, payment_method_id: paymentMethodId } = item;
        let serviceCall = active ? formService.enablePaymentMethod(vm.formId, paymentMethodId) : formService.disablePaymentMethod(vm.formId, paymentMethodId);
        
        serviceCall
            .then(function(resp) {                
                icToastService.showSimpleToast(resp);
            }).catch(function(err) {
                icToastService.showSimpleToast(err, true);
            });        
    }

    vm.$onInit = function () {
        formService
        .getPaymentMethods(vm.formId)
        .then(function(paymentMethods) {                
            vm.paymentMethods = paymentMethods;            
        }).catch(function(err) {                     
            throw(err.data);
        });   
    }
}];


export default {
    bindings: {         
        formId: '<',
    },
    controller: myController,
    controllerAs: 'vm',
    transclude: true,
    template    
};
import angular from 'angular';
export default angular.module('appFilters', [])
.filter('modules', function() {
    return function(input) {
        if(!Array.isArray(input)) {                    
            return [];
        };
        // Filters down to the parent service items (module)
        return input.filter(function (el) { return ( (el.parent_selected_service_id === null) || (el.parent_selected_service_id === el.selected_service_id) )});
    }
})
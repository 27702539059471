import template from './template-profile-images.html';

const myController = ['templateService', 'icToastService', function (templateService, icToastService ) {
    const vm = this;

    vm.loading = true;
    vm.headerImageloading = true;
    vm.errors = [];
    vm.template = [];
    vm.logo_images = [];
    vm.header_images = [];

    function getTemplate () {
        templateService
        .getTemplate(vm.templateId)
        .then(function(template) {                
            vm.template = template;
            vm.loading = false;
        });
    }

    vm.$onInit = function () {
        
        getTemplate();
        templateService
        .getImages(vm.templateId, 'logo')
        .then(function(images) {         
            vm.logo_images = images.map(x => ( {name: x.documentName, value: x.documentName, url:x.url}));
            
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });
        
        templateService
        .getImages(vm.templateId, 'header')
        .then(function(images) {         
            vm.header_images = images.map(x => ( {name: x.documentName, value: x.documentName, url:x.url}));
            vm.headerImageloading = false;
            
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });        
    }
    
    vm.updateTemplate = function() {
        templateService.updateTemplate(vm.templateId, vm.template)
        .then(function(res) {
            icToastService.showSimpleToast('Template updated successfully');
            getTemplate();
        })
    }


 
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

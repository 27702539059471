import template from './form-profile.html';

const myController = ['formService', 'icToastService', function (formService, icToastService ) {
    const vm = this;

    vm.loading = false;
    vm.errors = [];
    vm.templates = [];
    vm.form = {};
    vm.emailProfiles = [];
    vm.$onInit = function () {
        vm.loading = true;
        
        formService
        .getForm(vm.formId)
        .then(function(form) {                
            vm.form = form;
            vm.loading = false;    
        });

        formService
        .getEmailProfiles(vm.templateId)
        .then(function(emailProfiles) {                
            vm.emailProfiles = emailProfiles;
            vm.loading = false;
        });
    }
    vm.submitForm = function() {
        formService.updateForm(vm.formId, vm.form)
        .then(function(res) {
            icToastService.showSimpleToast('Form updated successfully');
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err.messages, true);  
        })
    }    
}];

export default {
        bindings: { formId: '<', templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

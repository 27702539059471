import template from './template-service-item.html';

const myController = ['templateServicesService', '$state', 'icToastService', function (templateServicesService, $state, icToastService ) {
    const vm = this;
    vm.service = {};
    vm.images = [];
    vm.forms = [];

    vm.serviceTypes = [
        'Wrapper',
        'Site Order',
        'Module',
        'Service'
    ]

    vm.$onInit = function () {

        vm.getService();

        templateServicesService
        .getServiceImages(vm.templateId)
        .then(function(images) {                
            vm.images = images;
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });        

        templateServicesService
        .getForms(vm.serviceId)
        .then(function(forms) {            
            vm.forms = forms;            
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });                
    }
    
    vm.getService = function () {
        vm.loading = true;
        templateServicesService
        .getService(vm.serviceId)
        .then(function(service) {            
            vm.service = service;            
            vm.loading = false;
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });        
    }

    vm.updateImage = function() {
        const { service_logo } = vm.service;
        templateServicesService.updateServiceImage(vm.serviceId, service_logo)
        .then(function(res) {
            icToastService.showSimpleToast('Service updated successfully');
            vm.getService();
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });            
    }

    vm.updateService = function(isValid) {        
        if(!isValid) {            
            return false;
        }        
        templateServicesService.updateService(vm.serviceId, vm.service)
        .then(function(res) {
            icToastService.showSimpleToast('Service updated successfully');
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });        
    }
    
    vm.deleteService = function () {
        templateServicesService
        .deleteService(vm.serviceId)
        .then(function(resp) {
            $state.go('.^')
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });
    }
}];

export default {
        bindings: { templateId: '<', serviceId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

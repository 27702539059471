import template from '../form-profile/form-profile.html';

const myController = ['formService', 'templateService', 'icToastService', function (formService, templateService, icToastService ) {
    const vm = this;

    vm.loading = false;
    vm.errors = [];
    vm.templates = [];
    vm.form = { invoice_term: 30, is_site_form: 1};
    vm.emailProfiles = [];
    vm.$onInit = function () {

        templateService
        .getTemplate(vm.templateId)
        .then(function(template) {
            vm.form = {
                ...vm.form, 
                template_id: vm.templateId,
                template_name: template.template_name
            };
        });

        formService
        .getEmailProfiles(vm.templateId)
        .then(function(emailProfiles) {                
            vm.emailProfiles = emailProfiles;
            vm.form = {
                ...vm.form,
                email_template_wrapper_id: emailProfiles[0].email_template_wrapper_id
            }
        });

    }
    // save form
    vm.submitForm = function() {
        formService.addForm(vm.templateId, vm.form)
        .then(function(res) {
            icToastService.showSimpleToast('Form successfully created');
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err.messages, true);  
        })
    }     
}];

export default {
        bindings: { templateId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };

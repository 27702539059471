import template from './form-modules.html';

const myController = ['formService', 'formModulesService', 'icToastService', function (formService, formModulesService, icToastService ) {
    const vm = this;
    vm.loading = true;
    vm.services = [];
    vm.addMode = false;
    vm.form = {};
    vm.moduleCount = true;
    vm.reloadServices = function () {
        formModulesService
        .getServicesView(vm.formId)
        .then(function(services) {
            vm.services = services;
            vm.loading = false;
            
            
            vm.moduleCount = vm.services.filter(x => !x.parent_template_service_id).length;
            
        });
    }
    vm.getFormConfig = function() {
        formService
        .getConfig(vm.formId)
        .then(function(form) {                
            vm.form = form;            
        });
    }

    vm.$onInit = function () {
        
        vm.reloadServices();
        vm.getFormConfig();
    }

    vm.addModule = function(serviceId) {
        formModulesService
        .addModule(vm.formId, serviceId)
        .then(function(resp) { 
            vm.reloadServices();
            vm.addMode = false;
            icToastService.showSimpleToast(resp);
            
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        });
    }
    vm.toggleAddMode = function () {
        vm.addMode = !vm.addMode;
    }
    
}];


export default {
    bindings: { formId: '<', templateId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};
    import template from './template-permissions.html';
    
    const myController = ['templateService', 'icToastService', function (templateService, icToastService) {
        const vm = this;

        vm.loading = true;
        vm.errors = [];
        vm.templates = [];
        vm.permissions = [];
        vm.$onInit = function () {
            templateService
            .getUsers(vm.templateId)
            .then(function(permissions) {                
                vm.permissions = permissions;
                vm.loading = false;
            });
        }
        vm.removePermission = function(item) {            
            const { user_id: userId } = item;
            const originalPermission = item.permission_level;
            item.permission_level = 0;
            templateService
            .deletePermision(vm.templateId, userId)
            .then(function(resp) {

            }).catch(function(err) {
                icToastService.showSimpleToast(err, true);
                item.permission_level = originalPermission;
            });

        }
        vm.incrementPermission = function(item) {
            item.permission_level +=1;
            const { user_id: userId, permission_level: permissionLevel } = item;
            templateService
            .updatePermision(vm.templateId, userId, permissionLevel)
            .then(function(resp) {                
                
            }).catch(function(err) {
                item.permission_level -=1;
                icToastService.showSimpleToast(err, true);
            });
        }        
    }];

    export default {
            bindings: { templateId: '<' },
            controllerAs: 'vm',
            controller: myController,
            template
        };


import template from './notification-profile.html';

const myController = ['notificationsService', '$state', 'icToastService', function (notificationsService, $state, icToastService ) {
    const vm = this;
    vm.events = [];
    vm.loading = true;
    vm.$onInit = function () {

        vm.getProfile();
       
    }
    
    vm.getProfile = function () {
        notificationsService
        .getProfile(vm.profileId)
        .then(function(events) {
            vm.events = events;
            vm.loading = false;   
        });
    }


    function showSimpleToast(title) {
        $mdToast.show(
            $mdToast.simple()
                .content(title)
                .hideDelay(2000)
                .position('bottom right')
                .action('OK')
                .highlightAction(true)
                .highlightClass('md-hue')
            );
    }
}];

export default {
        bindings: { templateId: '<', profileId: '<'},
        controllerAs: 'vm',
        controller: myController,
        template
    };
